<template>
  <div
    class="layout-container mb-48 md:mb-64"
    :class="{
      'px-0 md:px-56': isMobile && model.mobileSlider
    }"
  >
    <div v-if="isEpiEdit" class="mb-24">
      <div
        v-epi-edit="'MobileSlider'"
        class="btn btn--secondary btn--sm btn--edit mr-16 rounded-none px-12 epi-edit-button"
      >
        Use slider in mobile
      </div>
      <div
        v-epi-edit="'Small'"
        class="btn btn--secondary btn--sm btn--edit mr-16 rounded-none px-12 epi-edit-button"
      >
        Use small list
      </div>
    </div>
    <WysiwygWrapper
      v-if="model.text"
      v-epi-edit="'Text'"
      :style-config="{ 'wysiwyg mb-24 md:mb-32 md:w-3/4 lg:w-1/2': true }"
      :html="model.text"
      :class="{
        'px-24 md:px-0': isMobile && model.mobileSlider
      }"
    />
    <div
      v-if="(!model.mobileSlider && isMobile) || !isMobile"
      v-epi-edit="'Stores'"
      class="justify-center items-start grid gap-x-12"
      :class="{
        'gap-y-24 grid-cols-3 sm:grid-cols-4 md:grid-cols-none md:gap-x-24 md:gap-y-0 md:flex': model.small,
        'gap-y-32 grid-cols-1 sm:grid-cols-3 md:grid-cols-4 md:gap-x-24 md:gap-y-48': !model.small,
      }"
    >
      <GlobalsLinkHelper
        v-for="(store, index) in stores"
        :key="index"
        :to="store.url"
        class="block flex-shrink-0 md:flex-1 md:w-auto"
      >
        <StoreListingBlockItem
          :store="store"
          :small="(model.small || (isMobile && model.mobileSlider)) ?? false"
          :index="index"
        />
      </GlobalsLinkHelper>
    </div>
    <client-only v-else-if="isMobile && model.mobileSlider">
      <swiper
        v-epi-edit="'Stores'"
        :slides-per-view="'auto'"
        :space-between="isMobile ? 12 : 0"
        class="store-listing-swiper !px-24 md:!px-0"
      >
        <swiper-slide
          v-for="(store, index) in stores"
          :key="index"
          class="cursor-auto !w-1/4 md:!w-auto md:flex-1 md:max-w-208"
        >
          <GlobalsLinkHelper
            :to="store.url"
            class="block flex-shrink-0 md:flex-1 md:w-auto"
          >
            <StoreListingBlockItem
              :store="store"
              :small="(model.small || (isMobile && model.mobileSlider)) ?? false"
              :index="index"
            />
          </GlobalsLinkHelper>
        </swiper-slide>
      </swiper>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import type { StoreListingBlock } from '~/content-types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import { usePageContentStore } from '~/store/pageContent';
import type { IStoreListingBlockData } from '~/api-types';
const uiStore = useUiStore();
const { isEpiEdit } = storeToRefs(usePageContentStore());
const { isMobile } = storeToRefs(uiStore);

const props = defineProps<{
  model: StoreListingBlock;
}>();

const stores = computed(() => {
  const storeListingBlockData = props.model.pageData as IStoreListingBlockData;
  return storeListingBlockData.stores;
});

</script>
<style lang="postcss">
.store-listing-swiper {
  .swiper-wrapper {
    @apply md:gap-x-24;
  }
}
</style>
